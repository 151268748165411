html {
  position: relative;
}

body {
  font-family: "Open Sans", "Arial";
  color: #333;
  font-size: 13px;
  padding: 0;
  margin: 2px;
  background-color: #f1f1f1;
}

a {
  text-decoration: none;
}

.rightLayout {
  position: fixed;
  z-index: 10;
  vertical-align: top;
  margin-right: auto;
  color: #567;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-align: center;
  width: 200px;
  min-width: 200px;
  height: 100vh;
  box-shadow: 2px 4px 5px rgb(0 0 0 / 10%);
  transition: all ease 0s;
  background: #f9f9f9;
  padding: 0;
}

.rightLayout .companyLogo {
  height: 70px;
  width: 70px;
  margin: 10px auto;
  background: #fff;
  width: 60%;
  border-radius: 50%;
  position: relative;
  padding: 13px;
  box-shadow: 0px 0px 2px 2px #eee;
  max-width: 80px;
  max-height: 80px;
}

.rightLayout .hrCompany {
  color: #567;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-align: center;
}

.rightLayout ul {
  padding: 0;
  margin: 0;
}

.rightLayout ul a {
  color: #567;
  display: block;
  padding: 10px 15px;
  font-size: 14px;
}

.rightLayout li {
  text-align: left;
  display: block;
}

.rightLayout ul li a.active,
.rightLayout ul a:hover {
  background-color: rgba(114, 192, 44, 0.07) !important;
  text-decoration: none;
}

.rightLayout ul li a.active,
.rightLayout ul a:hover {
  color: #72c02c !important;
}

.rightLayout ul li a.active {
  background: rgba(0, 177, 205, 0.05);
  font-weight: 600;
}

.rightLayout ul a {
  color: #567;
  display: block;
  padding: 10px 15px;
  font-size: 14px;
}

.content{
  
}