@import url('./site.layout.css');
/* Bireysel - CV - Default Masterlarına ait css */
/*Layout*/
.containerFull{width:100%;background:#fff;-webkit-box-shadow:0 1px 1px 0 rgba(135,135,135,0.35);-moz-box-shadow:0 1px 1px 0 rgba(135,135,135,0.35);box-shadow:0 1px 1px 0 rgba(135,135,135,0.35)}
.header{min-height:30px;text-align:right}
.header .menuUst.fa{padding-right:3px}
.header .logo{padding:4px;text-align:left;min-height:70px;margin-bottom:-20px}
.header .logo:empty{min-height:unset;margin-bottom:24px}
.header .logo img{max-width:300px;max-height:60px;margin-top:5px}
div.menu{transition:all ease 1s;display:inline-block;margin-bottom:5px;margin-top:15px}
div.menu .menuClose{display:none}
ul.menu{list-style-type:none;margin:0px auto;color:white;padding:0}
ul.menu>li{display:inline-block!important}
ul.menu>li.active span, ul.menu>li.active i{color:#00b1cd;}
ul.menu>li.active a{color:#567}
ul.menu>li>a{border-left:none;color:#567;font-size:14px;padding:0 0px 3px;margin:0 15px;display:inline-block;border-bottom:1px solid transparent;}
ul.menu>li>a>i{margin-right:5px;width:14px;text-align:center}
ul.menu>li>a:hover{color:#00b1cd!important;border-bottom:1px dotted #00b1cd;background-color:transparent!important} /*Ana rengin .6 şeffaf rengi*/
.menuSayfaContainer{margin-bottom:10px}
.container{vertical-align:top;margin:0 auto}
.container .content{padding:12px 8px;background:white;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;min-height:calc(100vh - 220px);box-shadow:0px 0px 6px rgba(0, 0, 0, 0.1)}
.container .menuSayfa{padding:0 10px;position:relative;margin:0}
.container .menuSayfa .PH{font-size:20px;color:#fff;font-weight:normal;letter-spacing:normal;padding:8px 0;white-space:nowrap}
.container .menuSayfa .MW{background:none!important;box-shadow:none;-webkit-box-shadow:none}
.container .menuSayfa .menuSayfaMobil{color:#fff;position:absolute;right:20px;top:10px;font-size:20px}
.leftSidebar{max-width:40px}
.leftSidebar span{font-size:25px;display:inline-block;float:left;padding:6px 10px 6px 0}
.menuClose {text-align:right;color:#567;font-size:25px;position:absolute;right:10px;z-index:10}
/*Üst menü (eski)*/
.ALT_MENU_LINK{color:#fff;text-decoration:none;letter-spacing:.25px}
.ALT_MENU_LINK i{font-size:16px;margin-right:5px}
.ALT_MENU_LINK span{font-size:14px}
.ALT_MENU_LINK:hover{color:#f1f1f1}
.ALT_MENU_LINK:hover span{color:#f1f1f1;border-bottom:1px dotted #f1f1f1}
@media (min-width:1200px){.container{width:1170px}}