@import url("../vendor/tooltipster.bundle.min.css");
@import url("../vendor/tooltipster-sideTip-shadow.min.css");
.tableContainer{background-color:#fff;border:1px solid #ddd;padding:15px;margin-bottom:30px}
.tableBaslik {clear: both;text-align: left;padding-bottom: 10px;margin-bottom: 10px;font-size: 16px;color: #585f69;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;border-bottom: 1px solid #ccc;}
.tableBaslik i {font-size:24px;border-radius:50%;vertical-align:middle;padding:5px 10px;margin-right:8px;width:18px;text-align:center;color:#585f69;}
.tf.tn{max-width:800px;background:#fff;border-collapse:separate;border-spacing:0 15px}
.tf.tn>tbody{border:20px solid transparent}
.tf.tn>tbody>tr>td{font-size:14px}
.tf.tn .ALAN_BASLIK{padding-top:7px!important;line-height:20px;}
.tf.tn .ALAN_ACIKLAMA{display:none}
.tf.tn .ALAN_KONTROL{display:block;box-sizing:border-box}
.tf.tn .ALAN_KONTROL input[type=text],.tf.tn .ALAN_KONTROL input[type=email],.tf.tn .ALAN_KONTROL input[type=tel],.tf.tn .ALAN_KONTROL input[type=password]{width:100%;box-sizing:border-box;max-width:none;}
.tf.tn .ALAN_KONTROL input[type=text]:focus{border-color:#94a1a2!important;transition:ease .5s}
.tf.tn .ALAN_KONTROL textarea{width:100%;box-sizing:border-box}
/*select*/
.tn select.custom{width:100%!important;box-sizing:border-box;max-width:none;font-size:14px;}
/*radiobutton*/
/*.tn input[type="radio"]{display:none}
.tn input[type="radio"]+label{position:relative;margin-left:30px;box-sizing:border-box; }
.tn input[type="radio"]+label:before{position:absolute;content:'';width:13px;height:13px;border-radius:50%;border:1px solid #ccc;left:-20px;top:3px}
.tn input[type="radio"]:checked+label:before {border:1px solid #3498db;background-color:#3498db;box-shadow: inset 0 0 0 2.5px #fff}
/*
/*checkbox*/
/*.tn input[type="checkbox"]{display:none}
.tn input[type="checkbox"]+label{position:relative;margin-left:20px;box-sizing:border-box}
.tn input[type="checkbox"]+label:before{position:absolute;content:'';left:-20px;top:3px;border:1px solid #3498db;width:13px;height:13px}
.tn input[type="checkbox"]:checked+label:before {top:0;left:-16px;box-shadow: inset 0 0 0 2.5px transparent;width:6px;height:12px;border: solid #3498db;border-width: 0 2px 2px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
.tn input[type="checkbox"]:checked+label:after {border:1px solid #3498db;width:15px;height:15px}
.tn .textarea{width:100%!important}
*/
/*File Upload*/
.tn .fileUpload input[type="file"]{display:none}
.tn .fileUpload div.btn{background-color:#00b1cd;display:inline-block;padding:4px 7px;color:#fff;cursor:pointer}
.tn .fileUpload div.btn:before{content:'\f0ee';font-family:fontawesome;color:#fff;margin-right:5px}
.tn .fileUpload .dosyaAd{display:none;margin-left:10px}
.tn .fileUpload .dosyaAd span{background-color:#efefef;padding:3px 8px}
.tn .fileUpload .dosyaAd i.ikonSilCopKutu{color:#e55;margin-left:5px}

/*DateTime Picker*/
/*.ajax__calendar{display:block!important;visibility:visible!important}*/
.calendar .ajax__calendar_container{font-family:'Open sans',tahoma,verdana,helvetica!important;width:280px!important;border-color:#ececec!important;font-size:12px!important}
.calendar .ajax__calendar_container TABLE,
.calendar .ajax__calendar_container TD{font-size:14px!important}
.calendar .ajax__calendar_days,
.calendar .ajax__calendar_months,
.calendar .ajax__calendar_years{width:280px!important;height:255px!important}
.calendar .ajax__calendar_day{width:37px!important;height:37px!important;line-height:37px;text-align:center!important;padding:0!important}
.calendar .ajax__calendar_month,
.calendar .ajax__calendar_year{width:65px!important;height:80px!important}
.calendar .ajax__calendar_month br,
.calendar .ajax__calendar_year br{line-height:30px}
.calendar .ajax__calendar .ajax__calendar_dayname{border:none!important;padding:0!important;text-align:center!important;width:37px!important;font-weight:600}
.calendar .ajax__calendar_body{width:280px!important;height:255px!important}
.calendar .ajax__calendar .ajax__calendar_day{border-radius:50%}
.calendar .ajax__calendar .ajax__calendar_today .ajax__calendar_day,
.calendar .ajax__calendar .ajax__calendar_today .ajax__calendar_month,
.calendar .ajax__calendar .ajax__calendar_today .ajax__calendar_year{border-color:#959ea9!important}
.calendar .ajax__calendar .ajax__calendar_hover .ajax__calendar_day,
.calendar .ajax__calendar .ajax__calendar_hover .ajax__calendar_month,
.calendar .ajax__calendar .ajax__calendar_hover .ajax__calendar_year{background-color:#e6e6e6!important;border-color:#e6e6e6!important;color:#000!important}
.calendar .ajax__calendar .ajax__calendar_active .ajax__calendar_day,
.calendar .ajax__calendar .ajax__calendar_active .ajax__calendar_month,
.calendar .ajax__calendar .ajax__calendar_active .ajax__calendar_year{background-color:#e6e6e6!important;border-color:#e6e6e6!important;color:#000!important}

/*editor*/
.tn .ajax__htmleditor_editor_default .ajax__htmleditor_editor_toptoolbar{padding:0!important;background-color:#f5f5f5!important}
.tn .ajax__htmleditor_editor_default .ajax__htmleditor_editor_toptoolbar .ajax__htmleditor_toolbar_button:hover{background-color:#dedede!important}
.tn .ajax__htmleditor_editor_default .ajax__htmleditor_editor_toptoolbar .ajax__htmleditor_toolbar_button{background-color:#f5f5f5!important;margin:5px!important}
.tn .ajax__htmleditor_editor_base img.ajax__htmleditor_toolbar_button{width:13px!important;height:13px!important;padding:5px!important}
.tn .ajax__htmleditor_editor_base img.ajax__htmleditor_toolbar_button.ajax__htmleditor_toolbar_button_active{background-color:#dedede!important}

/* .tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-FormatBold-Inactive.gif']{content:url('/images/fa/bold.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-FormatBold-Active.gif']{content:url('/images/fa/bold.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-FormatItalic-Inactive.gif']{content:url('/images/fa/italic.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-FormatItalic-Active.gif']{content:url('/images/fa/italic.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-FormatUnderline-Inactive.gif']{content:url('/images/fa/underline.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-FormatUnderline-Active.gif']{content:url('/images/fa/underline.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-ListBullet-Inactive.gif']{content:url('/images/fa/list-ul.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-ListBullet-Active.gif']{content:url('/images/fa/list-ul.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-ListNum-Inactive.gif']{content:url('/images/fa/list-ol.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-ListNum-Active.gif']{content:url('/images/fa/list-ol.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-ColorFg-Inactive.gif']{content:url('/images/fa/tint.png')}
.tn img[src$='//ajax.aspnetcdn.com/ajax/act/18_1_0/Content/AjaxControlToolkit/Images/HtmlEditor.Ed-ColorFg-Active.gif']{content:url('/images/fa/tint.png')} */