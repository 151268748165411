/*HTML*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&&subset=latin,latin-ext');
/* @import url('../vendor/font-awesome.min.css'); */
@import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
@import url("./form.css");
body{font-family:"Open Sans", "Arial";color:#333;font-size:13px;padding:0;margin:2px;background-color:#f1f1f1}
strong, b{font-weight:600}
input,textarea, select{font-family:'Open sans', 'Arial'}
input[type=submit]{font-size:14px;-webkit-appearance:none;-moz-appearance:none;-moz-border-radius:0}
input[type=text],INPUT[type=password],input[type=email],input[type=tel]{padding:6px}
a{text-decoration:none;color:#0088cc}
a:hover{color:#66c4e9}
img{border:none}
table{border-collapse:collapse}
th{text-align:left}
input[type=text],input[type=password],input[type=number],input[type=email],input[type=tel],textarea,select{padding:6px;border:solid 1px #D3D3D3;font-family:"Open Sans", "Arial";}
input[type=text]:focus{border:1px solid #bbb}
input[type=file]{background-color:#fff;padding-left:0}
select[multiple]{height:auto}
select{padding:5px;max-width:500px;background:#fff}
select:disabled{background:#ddd}
select:-moz-focusring{color:transparent;text-shadow:0 0 0 #000}
label{padding-left:4px}
:focus{outline:none}
/*Genel*/
.CUSTOM_TABLE_INFO,.TABLE_INFO{width:100%;padding:4px;position:relative;margin:0 0 10px}
.TABLE_INFO .INFO{color:#555;font-weight:normal;border-left:5px solid rgba(50,122,213,.7);background-color:rgba(50,122,213,.2);padding:15px 8px 15px 40px!important;position:relative}
.TABLE_INFO .INFO:before{content:'\f129';color:#327ad5}
.TABLE_INFO .SUCCESS{color:#555;font-weight:normal;border-left:5px solid rgba(114,192,44,.7);background-color:rgba(114,192,44,.2);padding:15px 8px 15px 45px!important;position:relative}
.TABLE_INFO .SUCCESS:before{content:'\f058';color:#72c02c}
.TABLE_INFO .ERROR{color:#e73d4a;font-size:14px;border-left:5px solid rgba(231,61,74,.7);background-color:rgba(231,61,74,.1);padding:15px 8px 15px 45px!important;position:relative}
.TABLE_INFO .ERROR:before{content:'\f057';color:#e73d4a}
.CUSTOM_TABLE_INFO td:first-child{display:none}
.CUSTOM_TABLE_INFO .ERROR{color:#e73d4a;font-size:14px;border-left:5px solid rgba(231,61,74,.7);background-color:rgba(231,61,74,.1);padding:15px 8px 15px 80px!important}
.CUSTOM_TABLE_INFO .ERROR:before{content:'\f05e';color:#e73d4a;font-size:32px!important;left:25px!important}
.CUSTOM_TABLE_INFO .ERROR:before, .TABLE_INFO .INFO:before, .TABLE_INFO .SUCCESS:before, .TABLE_INFO .ERROR:before{font-family:fontawesome;font-size:24px;position:absolute;left:15px;top:50%;-moz-transform: translateX(0) translateY(-50%);-webkit-transform: translateX(0) translateY(-50%);-o-transform: translateX(0) translateY(-50%);-ms-transform: translateX(0) translateY(-50%);transform: translateX(0) translateY(-50%);}
.INFO{color:#6F6666;font-weight:600}
.SUCCESS{color:#6F6666;font-weight:600}
.ERROR{color:#e73d4a;font-size:14px}
.ALAN_KONTROL span.ERROR{color:#e73d4a;font-size:13px;background-color:#fdebed;padding:15px 8px!important}
.VALIDATOR span.ERROR{padding:4px 8px!important;background-color:#fdebed;}
.VALIDATOR span.ERROR:not([style*="none"]){display:inline-block!important;margin-top:5px}
.MV{color:#3598DC}
.KRMZ{color:#e73d4a}
.GRN{color:Green}
.VM{color:#08D;font-weight:600}
.ACIKLAMA{color:#555;line-height:22px}
.Y{font-size:11px;color:gray}
.P5{padding-top:5px}
.P8{padding-top:8px}
.P10{padding-top:10px}
.PL{color:#3598DC;text-decoration:none}
.PL:hover{color:#E43A45;text-decoration:underline}
.C_S,.C_E{background-repeat:no-repeat;padding-left:40px;height:auto;min-height:28px;line-height:2em;color:#6F6666;position:relative}
.C_S{background-color:rgba(114,192,44,.2)}
.C_E{background-color:rgba(231,61,74,.1)}
.C_S:before,.C_E:before{font-family:fontawesome;font-size:24px;position:absolute;left:10px;top:50%;-moz-transform: translateX(0) translateY(-50%);-webkit-transform: translateX(0) translateY(-50%);-o-transform: translateX(0) translateY(-50%);-ms-transform: translateX(0) translateY(-50%);transform: translateX(0) translateY(-50%);}
.C_S:before{content:'\f058';color:#72c02c;}
.C_E:before{content:'\f057';color:#e73d4a;}
.BL{color:#385495;text-decoration:none;padding:0 8px}
.BL:hover{text-decoration:underline}
.BT,.B_T{width:1%;white-space:nowrap;font-weight:600;padding-bottom:4px}
.B_L{border-bottom:solid 1px #dfdfdf;text-align:right;width:99%;padding-bottom:4px}
.SB,.SBM{color:#656565;font-size:13px;white-space:nowrap;width:1px;vertical-align:top}
.SBM{white-space:normal;width:auto}
.SA{color:#666;width:1px;vertical-align:top}
.SD{vertical-align:top;line-height:150%}
.BD{border:solid 1px #dcdcdc;vertical-align:top}
.KT{border:solid 1px #ccc;padding:10px 12px;margin:6px 0 15px 0;font-size:13px}
.ACIKLAMA .KT, .ACIKLAMA.KT{border:solid 1px #50c3cc;border-left:solid 8px #50c3cc;margin:5px 0 15px;background:rgba(80,195,204,.05);text-align:left}
.PICKLIST select {max-width:none;max-height:300px;overflow-x: scroll;}
/*Button Dalga Efekti*/
.ripple{position: relative;overflow:hidden;display:inline-block;vertical-align:middle}
.rippleEffect{position: absolute;border-radius: 50%;width: 50px;height: 50px;background: white;animation: ripple-animation 2s}
.ripple a:hover{color:#fff}
@keyframes ripple-animation {from {-moz-transform: scale(1);-webkit-transform: scale(1);-o-transform: scale(1);-ms-transform: scale(1);transform: scale(1);opacity: 0.4;}to {-moz-transform: scale(100);-webkit-transform: scale(100);-o-transform: scale(100);-ms-transform: scale(100);transform: scale(100);opacity: 0}}
/*UI Dialog*/
.ui-widget{font-family:'Open Sans', 'Arial';box-shadow:0 5px 15px rgba(0,0,0,.5)}
.ui-widget-content{border-radius:0;border:1px solid #ccc;} 
.ui-dialog-titlebar{background:none;border:none;font-weight:300;font-size:18px;padding:15px 12px!important;border-bottom:1px solid #ccc;border-radius:0;overflow:visible!important}
.ui-dialog-titlebar-close{border:none!important;background:none!important;font-weight:normal;box-shadow:none;padding:13px!important}
/*Kayıt Bulunamadı*/
.kayitBulunamadi{margin-top:80px;margin-bottom:80px}
.kayitBulunamadi>tbody>tr>td:first-child{display:none}
.kayitBulunamadi>tbody>tr>td:last-child{color:#e67e22!important;font-weight:400!important;font-size:14px!important;border-left:5px solid rgba(230,126,34,.8);background-color:rgba(230,126,34,.2);padding:15px 30px 15px 50px!important;position:relative;line-height:24px}
.kayitBulunamadi>tbody>tr>td:before{content:'\f06a';font-family:fontawesome;font-size:24px;position:absolute;left:5px;top:50%;-moz-transform: translateX(0) translateY(-50%);-webkit-transform: translateX(0) translateY(-50%);-o-transform: translateX(0) translateY(-50%);-ms-transform: translateX(0) translateY(-50%);transform: translateX(0) translateY(-50%);color:#e67e22;margin:0 10px}
/*Layout*/
.header .menuUst{padding:5px 5px 5px 0!important;text-align:right;vertical-align:middle;float:right;}
.header .menuUst a{text-decoration:none;color:#567;padding-right:8px}
.header .menuUst a:HOVER{text-decoration:none}
.header .menuUst .fa{padding-right:3px;color:#567}
.header .menuUst i{padding-right:3px;color:#567;font-size:12px}
.overlay{position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(0,0,0,.1);z-index:1;display:none}
.page{width:100%;min-width:760px;position:relative;background:#f5f5f5}
.altMetin{text-align:left;padding:10px 10px 0 0}
.altMetin a{float:right;margin-top:8px;margin-bottom:4px;color:#555}
/*Üst menü (eski)*/
.MW{width:100%;margin-bottom:4px}
.SAD{padding:14px}
.SAD IMG{padding-right:4px}
.DH{background:#1b5790}
.PH{font-weight:600;color:#345;padding-left:6px;height:46px}
.PH span{font-weight:400;font-size:11px;padding-left:6px}
.PH span.altBaslik:before{content:'/'; display:inline-block;margin-right:3px }
.PH span.altBaslik a{color:inherit}
.PM{background:#e5ecf3;border-bottom:solid 1px #e1e8ef}
/*Veri girişi*/
.tf{width:100%}
.tf>tbody>tr>td:not(:empty){padding:6px;border-collapse:collapse;border-spacing:0}
.ALAN_BASLIK,.ALAN_BASLIK_MULTI{text-align:right;color:#262626;white-space:nowrap;vertical-align:top;padding-left:8px!important;padding-top:10px!important;}
.ALAN_BASLIK_MULTI{min-width:120px;max-width:500px;white-space:normal}
.ALAN_ALT_BASLIK{/*font-weight:600;*/color:#778;white-space:nowrap;vertical-align:top;padding-top:5px;background-color:#fefffe}
.ALAN_KONTROL{white-space:nowrap;vertical-align:top;padding-top:8px;/*border-bottom:solid 1px #fcfcfd*/}
.ALAN_DUGME{padding-top:18px!important;padding-bottom:12px!important;white-space:nowrap}
.ALAN_DUGME .VAZGEC{margin-left:18px}
.ALAN_KONTROL_METIN{vertical-align:top;border-bottom:solid 1px #fcfcfd;line-height:15pt}
.ALAN_ACIKLAMA{vertical-align:top;padding:9px 4px 4px 9px;color:#000;background-color:#fafaff;border-bottom:solid 1px #e9f3ff;line-height:19px;font-size:12px}
.ORNEK{color:#06c;padding-top:4px}
.METIN{color:#333}
.BASLIK{color:#669}
.Z{color:#e73d4a}
.BY{padding:6px 12px;font-size:14px;line-height:20px;white-space:nowrap;background-color:#6088bd; color:#fff; width:auto; overflow:visible;cursor:pointer;border:none;letter-spacing:.3px;margin:0 5px;margin-left:0;display:inline-block}
.BY:hover{background-color:#43638e;color:#fff}
.BY:disabled{background:gray} 
.DUGME,.DUGME_AKTIF{border:none;cursor:pointer;font-weight:600;font-family:'Open Sans', Arial;font-size:13px!important;white-space:nowrap}
.DUGME_AKTIF{color:#e73d4a}
.KAYDET,.VAZGEC,.DUZENLE,.SIL{background:rgba(0,0,0,.2);border:none;cursor:pointer;padding:6px 10px;color:#333;transition:all ease .5s}
.VAZGEC:hover,.DUZENLE:hover,.SIL:hover{background:rgba(0,0,0,.3);color:#222}
.KAYDET{background:#6088bd;color:white}
.KAYDET:hover{background:#43638e}
.VAZGEC{}
/* .DUZENLE{background-image:url(/images/edit.gif)} */
.SIL{}
.LONG, tr.LONG textarea{width:300px!important}
.LARGE{width:500px!important}
.KB{color:#569;font-size:13px;padding-bottom:5px}
table[cs]{display:inline-block;border-collapse:collapse}
table[cs] td{padding:0;text-align:center}
table[cs] input{text-align:center;width:18px;margin-right:1px;font-weight:600;font-size:14px;color:black}
/*Grid mobile*/
.GM{width:100%;border:solid 1px #ddd}
.GM>tbody>tr>td{padding:6px;border-collapse:collapse;border-spacing:0}
.GM .GR>td,.GM .GAR>td{vertical-align:top;width:auto!important}
.GM .GR>td:first-child,.GM .GAR>td:first-child{border-left:solid 1px #ddd;padding-left:8px}
.GM .GR>td:last-child,.GM .GAR>td:last-child{text-align:left;border-right:solid 1px #ddd}
.GM .GR>td:nth-child(2),.GM .GAR>td:nth-child(2){padding-left:0;color:gray;width:1px!important}
.GM .sep td{font-size:1px;border:solid 1px #ddd;border-top:none}
.GM .sep+tr>td{padding-top:18px}
.GM .GAR+.sep{background-color:#f9f9f9}
/*Grid*/
.G{width:100%}
.G th{font-size:13px;font-weight:600;padding:12px 10px;color:black;background-color:#fcfcfc}
.G td{font-size:13px}
.GH{font-weight:600;color:#656565;text-align:left;vertical-align:top}
.G>tbody>:nth-child(1) th{border-bottom-width:2px}
.G>tbody>:nth-child(2) td{border-top:none}
.G>tbody>:nth-child(2){border-top:solid 1px #ddd}
.G>tbody>:last-child td{border-bottom:none}
.G>tbody>:last-child{border-bottom:solid 1px #ddd}
.G>tbody>tr>td:first-child{border-left:solid 1px #ddd}
.G>tbody>tr>td:last-child{border-right:solid 1px #ddd}
.GH A:LINK{color:#fff;text-decoration:none}
.GH A:HOVER{color:#FA8;text-decoration:none}
.GHC{text-align:center;width:1px;white-space:nowrap;padding-left:10px!important;padding-right:10px!important}
.GHR{text-align:right}
.GP{background-color:#89ABD9;font-family:Sans-Serif,Tahoma}
.GP SPAN{font-weight:600;font-size:14px;color:#fff}
.GP A:LINK{font-size:14px;color:#e1e1e1;text-decoration:none}
.GP A:HOVER{color:#FA8;text-decoration:none}
.GR{background-color:#fff}
.GAR{background-color:#f9f9f9}
.GR:hover,.GAR:hover{background-color:#E6EAEF}
.GR>td,.GAR>td{border:solid 1px #ddd;padding:10px 10px}
.GSR{background-color:#C0C9CF}
.GNH{text-align:center}
.GN{color:#456;text-align:right;font-size:smaller!important;width:1px}
.GC,.GCH{border-top-style:none;width:1%}
.GCH{vertical-align:bottom}
.GL,.LB{color:#578EBE;text-decoration:none}
.GL:visited{color:#67809F}
.GL:hover,.LB:hover{color:#e55;text-decoration:underline}
.GLB{color:#333;text-decoration:none}
.GLB:hover{text-decoration:underline}
.G a>i{font-size:16px;color:#567}
.G a>i:hover{color:#3598DC;transform:scale(1.3)}
.G a>i.ikonSilCopKutu{color:#e55;}
.G.GE>tbody>tr, .G.GE>tbody>tr>td{border:none}
/* Üst menü container (div) */
.dm{padding:4px 0 4px 0;width:100%;height:80px;font-size:11px;text-align:center;}
.dm > div{float:right}
.dm .actv{background-color:#f9f9f9;border-top:dashed 1pt #eee;border-bottom:dashed 1pt #eee}
.dm n{display:block}
.dm .OM i {color:#0088cc}
/* Üst menü item (div) */
.dm > div > div,.drm-content > div{text-align:center;padding:8px 0 8px 0;width:80px;background-color:white;border-left:solid 1pt #eee;float:left;z-index:2}
.dm > div > div:last-child{border-right:solid 1pt #eee}
.dm > div > div:hover,.drm-content > div:hover{background-color:#f9f9fc;-webkit-box-shadow:0 0px 1px 0 rgba(135,135,135,0.35);-moz-box-shadow:0 1px 1px 0 rgba(135,135,135,0.35);box-shadow:0 1px 1px 0 rgba(135,135,135,0.35)}
/* Üst menü gizli menü container */
.drm{position:relative;display:inline-block;width:48px!important;border-right:none!important}
/* Üst menü drop down buton */
.dropbtn{display:block;height:65px;cursor:pointer}
.dropbtn:before{content:'\f0c9';font-family:fontawesome;font-size:30px;color:#65828f;margin-top:16px;display:inline-block}
/* Üst menü item container */
.drm-content{display:none;position:absolute;padding-top:14px;left:-81px;-webkit-box-shadow:0 1px 1px 0 rgba(135,135,135,0.35);-moz-box-shadow:0 1px 1px 0 rgba(135,135,135,0.35);box-shadow:0 1px 1px 0 rgba(135,135,135,0.35);background:#fff;z-index:100}
/* drop down menu item */
.drm-content > div{border-left:none;border-top:solid 1pt #eee;padding:12px 0 12px 0;width:130px!important}
.drm-content n{display:inline;padding-left:4px}
.drm-content img {max-width:18px;padding-left:4px;float:left}
.drm-content .OM>div{padding-left:6px;margin-top:-4px;float:left;max-width:100px;overflow:hidden;white-space:nowrap;min-height:initial}
.drm-content .OM>i{float:left;margin-left:7px;width:20px;font-size:1.5em}
.drm:hover .drm-content{display:block}
/*Ozgecmis*/
.dm #ozgecmis{float:none;white-space:nowrap}
.dm #ozgecmis>div{display:inline-block;vertical-align:top;float:none;min-width:80px;max-width:150px;width:9%!important;height:60px}
.dm #ozgecmis .drm{min-width:48px!important;max-width:48px!important;padding-top:0}
/*Alt menü*/
.altMenu{text-align:center;padding-bottom:24px}
.altMenu div#altMenu{position:relative;display:inline-flex}
.altMenu div#altMenu:before,.altMenu div#altMenu:after{content:'';height:5px;display:inline-block;position:absolute;top:25%;-moz-transform: translateX(0) translateY(-50%);-webkit-transform: translateX(0) translateY(-50%);-o-transform: translateX(0) translateY(-50%);-ms-transform: translateX(0) translateY(-50%);transform: translateX(0) translateY(-50%);left:40px}
.altMenu div#altMenu:before{background:#ddd}
.altMenu div#altMenu:after{background:#72c02c}
.altMenu div#altMenu>div{border:none;background:transparent;position:relative;width:85px;z-index:1}
.altMenu div#altMenu>div:hover{box-shadow:none}
.altMenu div#altMenu>div div{font-size:11px}
.altMenu n{margin-left:5px;display:block}
.altMenu .actv .OM > div {color:#72c02c}
.altMenu div#altMenu .OM i{font-size:3em;color:#ddd;background:#fff}
.altMenu div#altMenu .OM i.fa-circle{color:#72c02c}
.altMenu div#altMenu .actv .OM i{color:#72c02c}
.altMenu div#altMenu > div:hover i{color:#d0eab9}
.altMenu div#altMenu > div:hover div{color:#72c02c}
.OM{color:#67809F;text-decoration:none}
.OM:hover{color:#e73d4a}
.OM>div{padding-top:5px;min-height:30px}
/*Adım*/
.CV_ADIM_P{background-color:#f1f1ff;width:1%;min-width:170px;border-right:solid 1px #dfdddd;vertical-align:top;padding:0!important}
A.CV_ADIM,A.CV_ADIM_AKTIF{color:#333;text-decoration:none;font-size:13px}
A.CV_ADIM:HOVER,A.CV_ADIM_AKTIF:HOVER{color:#c08}
A.CV_ADIM_AKTIF{font-weight:600;color:#e73d4a}
A.CV_ALT_ADIM,A.CV_ALT_ADIM_AKTIF{font-size:10px;color:#555;letter-spacing:1px;text-decoration:none;white-space:nowrap}
A.CV_ALT_ADIM:HOVER,A.CV_ALT_ADIM_AKTIF:HOVER{color:#c08}
A.CV_ALT_ADIM_AKTIF{font-weight:600;color:#e73d4a}
.CV_ADIM_KAYDET,.CV_ADIM_KAYDET_AKTIF{cursor:pointer;background-color:#6088bd!important;color:#fff;padding:6px 10px;border:none}
.CV_ADIM_KAYDET_AKTIF{background-color:#43638e!important}
A.SONRAKI_ADIM{padding:5px 0 3px 27px;text-decoration:none;color:#335;letter-spacing:1pt;font-size:12px}
a.SONRAKI_ADIM:before{content:'\f112';font-family:fontawesome;-moz-transform:rotateY(180deg);-webkit-transform:rotateY(180deg);-o-transform:rotateY(180deg);-ms-transform:rotateY(180deg);transform:rotateY(180deg);display: inline-block;margin-right:5px}
A.SONRAKI_ADIM:HOVER{color:#933;text-decoration:underline}
.D{color:#3598dc;padding-left:9px;text-decoration:none}
.D:visited{color:#3598dc}
.D:hover{color:#32c5d2}
.ATLA{padding-left:20px;height:18px;font-weight:600;color:#333;text-decoration:none}
.ATLA:before{content:'\f112';font-family:fontawesome;-moz-transform:rotateY(180deg);-webkit-transform:rotateY(180deg);-o-transform:rotateY(180deg);-ms-transform:rotateY(180deg);transform:rotateY(180deg);display:inline-block;margin-right:5px}
.ATLA:hover{color:#e73d4a}
#loading {width: 100%;height: 100%;top:0;left:0;position:fixed;display:block;opacity:0.7;background-color:#fff;z-index:99;text-align:center}
#loading-image {position:absolute;top:100px;-moz-transform: translateX(-50%) translateY(0%);-webkit-transform: translateX(-50%) translateY(0%);-o-transform: translateX(-50%) translateY(0%);-ms-transform: translateX(-50%) translateY(0%);transform: translateX(-50%) translateY(0%);z-index:100}
/*Switch*/
.switch{padding-left:2px;position:relative;display:inline-block;width:44px;height:20px;vertical-align:middle;margin-right:8px;padding-left:2px}
.switch input{display:none}
.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s}
.slider:before{position:absolute;content:"";height:18px;width:18px;left:1px;bottom:1px;background-color:white;-webkit-transition:.4s;transition:.4s}
input:checked + .slider{background-color:#66bb6a}
input:focus + .slider{box-shadow:0 0 1px #66bb6a}
input:checked + .slider:before{-webkit-transform:translateX(26px);-ms-transform:translateX(26px);transform:translateX(26px)}
.slider.round{border-radius:12px}
.slider.round:before{border-radius:50%}
.editor textarea {padding:6px}
.editor .toolbar{margin-bottom:8px}
.editor i{padding:2px 6px;margin-right:2px;cursor:pointer}
.editor .color{padding:0 6px;margin-right:1px;cursor:pointer;opacity:.6}
/*Tema*/
.container .menuSayfa, .containerFull.menuSayfaContainer{background:#00b1cd!important}
.kutuGolge{box-shadow:0.4px 0.7px 1px 0.4px #ccc;-moz-box-shadow:0.4px 0.7px 1px 0.4px #ccc;-webkit-box-shadow:0.4px 0.7px 1px 0.4px #ccc}
.sayfalama, .SAYFA{color:silver;padding:24px 0;text-align:center}
.sayfalama .sayfaNo, .aktifSayfa, .SAYFA .sayfaNo{color:#666;background:#fff;min-width:16px;height:16px;text-align:center;border:1px solid #ccc;border-radius:2px;cursor:pointer;display:inline-block;padding:3px;margin:0 3px;vertical-align:middle}
.sayfalama .aktifSayfa, .SAYFA .aktifSayfa, .sayfalama .sayfaNo:hover,  .SAYFA .sayfaNo:hover{color:#fff;background-color:#00b1cd;border-color:#00b1cd}
.alertify .ajs-footer .ajs-buttons .ajs-button{text-transform:none!important}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok{background-color:#3593d2!important;color:#fff!important}
.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok:hover, .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-cancel:hover{cursor:pointer!important}
/*Form  Tasarımı*/
.aciklamaIsareti{font-size:14px;color:#00b1cd;margin-left:10px}
.tooltipster-sidetip.tooltipster-shadow .tooltipster-box{box-shadow:0 0 10px 3px rgba(0,0,0,.1)}
.tooltipster-sidetip .tooltipster-content{line-height:20px!important}
.dtp i{color:cadetblue;padding-left:4px;cursor:pointer}
.cld{width:275px;font-size:13px;user-select:none;background:#fff;box-shadow:0 0 5px rgba(0,0,0,.4);position:absolute;z-index:10;margin-top:9px}
.cld-table{table-layout:fixed;border-spacing:0;border-collapse:collapse;margin:auto}
.cld-cell{line-height:35px;width:35px;text-align:center}
.cld-day-name{font-size:11px;line-height:20px;padding-top:10px;color:#aaa;font-weight:600}
.cld-date{cursor:default;border:1px solid #eee;font-weight:600}
.cld-date[data-valid=true]{cursor:pointer}
.cld-date[data-valid=true]:hover{background:rgba(0,0,0,.2)}
.cld-header{text-align:center;position:relative;line-height:40px;font-size:14px}
.cld-button{position:absolute;top:0;width:30px;padding:0 5px;border:1px solid #fff;line-height:35px;color:blue}
.cld-button>i{line-height:35px}
.cld-button:hover{cursor:pointer;background:rgba(0,0,0,.2);border:1px solid #20c3fd;border-radius:3px}
.cld-button-decrease{left:0}
.cld-button-increase{right:0}
.cld-is-edge,.cld-is-ok{color:#333}
.cld-is-outside,.cld-is-other-month{color:#ddd}
.cld-date[data-state]{border-style:double}
.cld-date[data-state=pending],.cld-date[data-state=start],.cld-date[data-state=end]{background:#07a2d9!important;border-color:#07a2d9}
.cld-date[data-state=between]{background:#bfe4f1;border-color:#20c3fd}
@media print{.menu{display:none}.menuUst{display:none}}
